<template>
  <v-container fluid class="pa-0 ma-0">

    <v-row no-gutters>

      <v-col cols="12">
        <v-toolbar
          dense
          flat
        >
          <v-toolbar-title>
            <v-btn
              text
              small
              @click="goList"
            >
              <v-icon small>mdi-view-list</v-icon>
              목록
            </v-btn>
            <v-btn
              v-show="!article.status && !article.answeredAt && article.email === this.$store.state.ui.email"
              text
              small
              @click="editQuestion"
            >
              <v-icon small color="success">mdi-pencil</v-icon>
              수정
            </v-btn>
            <v-btn
              v-show="!article.status && !article.answeredAt && article.email === this.$store.state.ui.email"
              text
              small
              @click="deleteQuestion"
            >
              <v-icon small color="error">mdi-trash-can-outline</v-icon>
              삭제
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>
      </v-col>

      <v-col v-show="!isId" cols="12">
        <v-alert
          prominent
          type="warning"
          class="ma-4 pa-10"
        >존재하지 않는 데이터 입니다</v-alert>
      </v-col>

      <!-- 구분: 기본정보 -->
      <v-col v-show="isId" cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="rounded-lg mx-4 mt-2 mb-2 py-2"
            width="1000"
          >
            <v-chip
              small
              label
              dark
              :color="article.statusColor"
              class="ml-4 mr-1 mt-2 mb-0"
            >{{ article.mStatus }}</v-chip>
            <v-card-title class="my-0">
              <v-icon class="mr-2">mdi-chevron-right</v-icon>
              {{ article.subject }}
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-row>
      </v-col>

      <!-- 구분: 문의내용 -->
      <v-col v-show="isId" cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="rounded-lg mx-4 mt-2 mb-2 py-2"
            width="1000"
          >
            <v-card-title class="my-0">
              <v-icon class="mr-2">mdi-account-question</v-icon>
              문의내용
              <v-spacer></v-spacer>
              <v-card-subtitle class="py-0">
                {{ article.name }}({{ article.email}}) / {{ strDateFormat2(article.createdAt) }}
              </v-card-subtitle>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="text-body-2 px-3 py-2">
                  <div
                    id="vue2editorViewer"
                    class="vue2editorViewer text-body-1"
                    v-html="article.question"
                  ></div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <!-- 구분: 답변내용 -->
      <v-col v-show="isId" cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="rounded-lg mx-4 mt-2 mb-2 py-2"
            width="1000"
          >
            <v-card-title class="my-0">
              <v-icon class="mr-2">mdi-comment-check</v-icon>
              답변내용
              <v-spacer></v-spacer>
              <v-card-subtitle class="py-0">
                <v-chip
                  v-show="article.status === 1 && article.answeredAt"
                  label
                >
                  Lawork 관리자 / {{ article.status === 1 && article.answeredAt ? `${strDateFormat2(article.answeredAt)}` : '' }}
                </v-chip>
              </v-card-subtitle>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="text-body-2 px-3 py-2">
                  <div
                    id="vue2editorViewer"
                    class="vue2editorViewer text-body-1"
                    v-html="article.answer"
                  ></div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <p></p>
        </v-row>
      </v-col>

    </v-row>

    <!-- !! 이용문의 등록 다이얼로그 -->
    <qna-add-dialog ref="qnaAddDialog" :article="article"></qna-add-dialog>

    <!-- 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'
// @: filters
import strDateFormat2 from '@/filters/strDateFormat2'

import qnaAddDialog from '@/components/qna/AddDialog' // 이용문의 등록창

// 구분: 현재 디렉토리명
const currDir = 'qna'

export default {
  components: {
    qnaAddDialog
  },

  data: () => ({
    // 구분: 게시물 데이터
    articleId: 0,
    article: {},
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 오버레이 로딩
    overlay: false
  }),

  watch: {
    '$route' (to, from) {
      // console.log(`watch.route`)
      // console.log(to.params)
      // !! 넘겨준 파라미터가 있는 경우. 등록시엔 -R을 제거해야 한다.
      const paramIds = to.params.id.split('-')
      this.articleId = parseInt(paramIds[0], 10)
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 수정시엔 '-E' 가 붙어서 넘어온다
        this.articleId = parseInt(paramIds[0], 10)
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        this.articleId = parseInt(paramIds[0], 10)
        // 넘어온 id로 해당 게시물 정보를 가져와야 한다
        this.getArticle(this.articleId)
      }
    }
  },

  computed: {
  },

  mounted () {
    // console.log(this.$store.state.token)

    // 중요: 넘어온 id를 매칭시켜야 한다!
    // 등록시엔 '-R' 이 붙어오므로 분리해서 적용한다. 안그러면 뷰페이지에서 목록으로 보낼때마다 초기화 해버린다.
    const paramIds = this.$route.params.id.split('-')
    this.articleId = parseInt(paramIds[0], 10)

    // 넘어온 id로 해당 게시물 정보를 가져와야 한다
    this.getArticle(this.articleId)
  },

  methods: {
    strDateFormat2,
    dummy () {
      console.log('dummy dummy ~ ')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message, color: 'error', timeout: 5000, shaped: true })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // 구분: 상세정보 가져오기
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const { data } = await this.$axios.get(`lawork/member/getQnaById/${id}`)
        if (!data.success) throw new Error(`[ERR-1] ${data.message}`)
        if (data.article) {
          this.article = data.article
        } else {
          // throw new Error('존재하지 않는 데이터 입니다!')
          this.isId = false
          this.overlay = true
          setTimeout(this.goList, 1000)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 수정 - 답변대기중인 경우만 가능
    async editQuestion () {
      try {
        const result = await this.$refs.qnaAddDialog.open('이용문의', { width: 800 }, 1, this.article)
        if (result) {
          // 리프레시코드
          // 중요: 수정시엔 리프레시를 의미하는 '-E' 을 붙여서 보낸다.
          this.$router.push(`/${currDir}/${result}-E`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 삭제 - 답변대기중인 경우만 가능
    async deleteQuestion () {
      try {
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = '정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.'
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/member/deleteQuestion/${this.articleId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // !! 리스트 리프레시
          await sleep(300).then(() => {
            this.$router.push(`/${currDir}/LR`)
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
.vue2editorViewer p {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  /* padding-top: 2px !important; */
  /* padding-bottom: 2px !important; */
}
iframe {
  width: 640px !important;
  height: 360px !important;
}
</style>
